
/////////////////Static table created during develope

// import React from 'react'
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import IconButton from '@mui/material/IconButton';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// function PersistencyGrid() {
//     const [age, setAge] = React.useState('');

//     const handleChange = (event) => {
//         setAge(event.target.value);
//     };
//     return (
//         <div className='lyt-grid-table'>
//             <Accordion className='bs-table-accordion typ-2'>
//                 <AccordionSummary
//                     expandIcon={<span className='icon icon-chevron-down'></span>}
//                     aria-controls="panel1-content"
//                     id="panel1-header"
//                 >
//                     <ul className='acc-list'>
//                         <li className='acc-item'>
//                             <span className='key'>Agent name</span>
//                             <span className='pair'>Sanjeev Kumar Singh</span>
//                         </li>
//                         <li className='acc-item'>
//                             <div className='bs-table'>
//                                 <table>
//                                     <thead>
//                                         <tr>
//                                             <th>Details</th>
//                                             <th>NOP</th>
//                                             <th>APE</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr className='bg-grey'>
//                                             <td>13th month paid</td>
//                                             <td>3902</td>
//                                             <td>3902</td>
//                                         </tr>
//                                         <tr>
//                                             <td>13th month not paid</td>
//                                             <td>3902</td>
//                                             <td>3902</td>
//                                         </tr>
//                                         <tr className='bg-grey'>
//                                             <td>13th month total</td>
//                                             <td>3902</td>
//                                             <td>3902</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </li>
//                     </ul>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                     <ul className='acc-list'>
//                         <li className='acc-item'>
//                             <div className='bs-table'>
//                                 <table>
//                                     <tbody>
//                                         <tr>
//                                             <td>13th month %</td>
//                                             <td>
//                                                 <div className='mod-tag down'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className='mod-tag'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr className='bg-grey'>
//                                             <td>MTD</td>
//                                             <td>1921.23</td>
//                                             <td>8202.2</td>
//                                         </tr>
//                                         <tr>
//                                             <td>13th M paid <span className='cm-line-break'>but 25th M not paid</span></td>
//                                             <td>1237</td>
//                                             <td>7872</td>
//                                         </tr>
//                                         <tr className='bg-grey'>
//                                             <td>Drop rate</td>
//                                             <td>
//                                                 <div className='mod-tag down'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className='mod-tag'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </li>
//                     </ul>
//                 </AccordionDetails>
//             </Accordion>
//             <Accordion className='bs-table-accordion typ-2'>
//                 <AccordionSummary
//                     expandIcon={<span className='icon icon-chevron-down'></span>}
//                     aria-controls="panel1-content"
//                     id="panel1-header"
//                 >
//                     <ul className='acc-list'>
//                         <li className='acc-item'>
//                             <span className='key'>Agent name</span>
//                             <span className='pair'>Prashant Kumar Todi</span>
//                         </li>
//                         <li className='acc-item'>
//                             <div className='bs-table'>
//                                 <table>
//                                     <thead>
//                                         <tr>
//                                             <th>Details</th>
//                                             <th>NOP</th>
//                                             <th>APE</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr className='bg-grey'>
//                                             <td>13th month paid</td>
//                                             <td>3902</td>
//                                             <td>3902</td>
//                                         </tr>
//                                         <tr>
//                                             <td>13th month not paid</td>
//                                             <td>3902</td>
//                                             <td>3902</td>
//                                         </tr>
//                                         <tr className='bg-grey'>
//                                             <td>13th month total</td>
//                                             <td>3902</td>
//                                             <td>3902</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </li>
//                     </ul>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                     <ul className='acc-list'>
//                         <li className='acc-item'>
//                             <div className='bs-table'>
//                                 <table>
//                                     <tbody>
//                                         <tr>
//                                             <td>13th month %</td>
//                                             <td>
//                                                 <div className='mod-tag down'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className='mod-tag'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                         <tr className='bg-grey'>
//                                             <td>MTD</td>
//                                             <td>1921.23</td>
//                                             <td>8202.2</td>
//                                         </tr>
//                                         <tr>
//                                             <td>13th M paid <span className='cm-line-break'>but 25th M not paid</span></td>
//                                             <td>1237</td>
//                                             <td>7872</td>
//                                         </tr>
//                                         <tr className='bg-grey'>
//                                             <td>Drop rate</td>
//                                             <td>
//                                                 <div className='mod-tag down'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className='mod-tag'>
//                                                     <span className='value'>29%</span>
//                                                     <span className='icon icon-trending'></span>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </li>
//                     </ul>
//                 </AccordionDetails>
//             </Accordion>
//             <div className='pagination-wrap'>
//                 <div className='left-wrap'>
//                     <IconButton disableRipple className='btn btn-search'>
//                         <span className='icon icon-prev'></span>
//                     </IconButton>
//                     <IconButton disableRipple className='btn btn-search'>
//                         <span className='icon icon-next'></span>
//                     </IconButton>
//                 </div>
//                 <div className='right-wrap'>
//                     <span>Page</span>
//                     <div className='bs-select typ-sm'>
//                         <FormControl fullWidth>
//                             <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 value={age}
//                                 label="Age"
//                                 onChange={handleChange}
//                                 IconComponent={(props) => (
//                                     <span
//                                         {...props}
//                                         className={`icon-chevron-down ${props.className}`}
//                                     ></span>
//                                 )}
//                                 MenuProps={{
//                                     classes: {
//                                         paper: "bs-menu-dropdown",
//                                     },
//                                 }}
//                                 displayEmpty
//                             >
//                                 <MenuItem value="">1</MenuItem>
//                                 <MenuItem value={1}>2</MenuItem>
//                                 <MenuItem value={2}>3</MenuItem>
//                                 <MenuItem value={3}>4</MenuItem>
//                             </Select>
//                         </FormControl>
//                     </div>
//                     <span>of 10</span>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default PersistencyGrid

///////Below is the production code

import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { amountUnit } from '../../../../../utils/helper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Button from '@mui/material/Button';

function PersistencyGrid({ data, filterRange, cardName, advisorcode, setAdvisorcode, filteredData,handlePoicyLevelDetails ,SetshowUnpaidData,showUnpaidData}) {
    let apiData = data;
    const colname = (values) => {

        if (apiData?.columnname.some(colData => colData.colname === values)) {
            const getDisplayName = apiData?.columnname.find(colData => colData.colname === values);
            return getDisplayName?.displayName;
        }
    }



    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 4;
    const totalPages = Math.ceil(filteredData?.length / recordsPerPage);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const currentRecords = filteredData?.slice(
        currentPage * recordsPerPage,
        (currentPage + 1) * recordsPerPage
    );

    const handleAdvisorClick = (code, name) => {
        const drillDown = localStorage.getItem("drillDown");
        const dataArray = drillDown ? JSON.parse(drillDown) : []
        const currDrillDown = { "code": code, "name": name }
        const newArray = [...dataArray, currDrillDown];
        localStorage.setItem("drillDown", JSON.stringify(newArray))
        const getLength = newArray.length - 1;
        setAdvisorcode(newArray[getLength].code)
        window.scrollTo(0, 0)
    }



    return (

        <div className='lyt-grid-table'>
                                <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value=""
                                    renderValue={(selected) => showUnpaidData}
                                    onChange={(e) => handlePoicyLevelDetails(e.target.value)}
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="Unpaid Policy Data">Unpaid Policy Data</MenuItem>
                                    <MenuItem value="Team Data">Team Data</MenuItem>
                                    {/* <MenuItem value="pdf">Export to PDF</MenuItem> */}
                                </Select>
                            </FormControl>

            {currentRecords?.map((item, index) => (

                <Accordion className='bs-table-accordion'>



                    <AccordionSummary
                        expandIcon={<span className='icon icon-chevron-down'></span>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <ul className='acc-list'>
                            <li className='acc-item' >

                           { (showUnpaidData == "Unpaid Policy Data")  ? (

                                <span className='pair'>{item.policy_number} - {item.Customer_Name}</span>) : (<span className='pair'>{item.agnt_nm} - {item.agnt}</span>) }

                                {/* <span className='pair'>{item.advisorcode}</span> */}
                            </li>

                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "13M_Percentage" || key === "25M_Percentage" || key === "37M_Percentage" || key === "49M_Percentage" || key === "61M_Percentage" ) && (

                                        <li className='acc-item' >

                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                    {/* removed the % sign on 31st dec24 */}
                                                    <span className='value'>
                                                        {value}
                                                    </span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </AccordionSummary>

                    <AccordionDetails>
                        <ul className='acc-list'>
                            {Object.entries(item).map(([key, value], i) => {

                                return (

                                    (key === "ape" ) && (

                                        // (key === "13M_Percentage" || key === "25M_Percentage" || key === "37M_Percentage" || key === "49M_Percentage" || key === "61M_Percentage" ) && (

                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                <div className='mod-indicator'>
                                                    {cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "nop"  ?

                                                        amountUnit(value, filterRange)
                                                        :
                                                        value
                                                    }<span className={`icon icon-${value > 0 ? "up" : "down"}`} ></span>
                                                </div>
                                            </span>
                                        </li>
                                    )

                                )
                            })}

                            {/* {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "13M_Unpaid" || key === "13M_Unpaid" ) && (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {( cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "nop") ?
                                                    amountUnit(value, filterRange) : value
                                                }
                                            </span>
                                        </li>
                                    )
                                )
                            })} */}

                            {Object.entries(item).map(([key, value], i) => {


                                return (
                                    key !== "id" && key !== "agnt_nm" && key !== "agnt" && key !== "ytd%" && key !== "mtdactual" && key !== "lysmactual" && key !== "ytdactual" && key !== 'mtdplan' && key !== 'lysmplan' &&
                                    key !== '25M_Percentage' &&
                                    key !== '37M_Percentage' &&
                                    key !== '49M_Percentage' &&
                                    key !== '61M_Percentage' &&
                                    key !== '13M_Percentage' &&
                                    key !== 'branchmanagercode' &&
                                    key !== 'branchmanagername' &&
                                    key !== 'name' &&
                                    key !== 'ape' &&
                                    key !== 'wfyp' &&
                                    key !== 'applctn_nmbr' &&
                                    key !== 'APE_Unpaid' &&
                                    key !== 'APE_Total' &&
                                    // key !== 'mdl_prmm' &&
                                    key !== 'bse_plcy_prm_pyng_stts_cd' &&
                                    key !== 'APE_Drop_Rate' &&
                                    key !== 'agncy_zone' &&
                                    key !== 'src_brnch_cd' &&
                                    key !== 'NOP_Drop_Rate' &&
                                    key !== 'NOP_Percentage' &&
                                    key !== 'NOP_Total' &&
                                    key !== 'NOP_Unpaid' &&
                                    key !== 'Region' &&
                                    key !== 'bill_freq' &&
                                    key !== 'APE_Paid' &&
                                    key !== 'NOP_Paid' &&
                                    key !== 'APE_Percentage' &&

                                    (

                                        <li className='acc-item' >

                                            <span className='key'> {   ( (key != "plcy_stts_cd" && key != "mdl_prmm" )  ) ? (colname(key) ):(key)  }</span>

                                             <span className='pair'>
                                                {key === "ftdactual" ? (
                                                    <div className='mod-indicator'>
                                                        {(cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "nop" ) ?
                                                            amountUnit(value, filterRange)  : value} <span className={`icon icon-${value > 0 ? "up" : "down"}`} ></span>
                                                    </div>
                                                ) :

                                                (key === '13M_Paid' || key === '13M_Unpaid' || key === '13M_Unpaid' || key === '25M_Paid' || key === '25M_Percentage' || key === '25M_Total' || key === '25M_Unpaid' || key === '37M_Paid' || key === '37M_Percentage' || key === '37M_Total' || key === '37M_Unpaid' || key === '49M_Paid' || key === '49M_Percentage' || key === '49M_Total' || key === '49M_Unpaid'|| key === '61M_Paid' || key === '61M_Percentage' || key === '61M_Total'   || key === '61M_Unpaid' || key === 'bse_plcy_prm_pyng_stts_cd'  ) && (cardName.toLowerCase() === "ape") ? (
                                                    amountUnit(value, filterRange)) : value

                                                    // the chages have been made here on 2nd Jan 25 at line 419

                                                // key === 'nop' || key === 'premium'  ?
                                                // (
                                                //     amountUnit(value, filterRange)) : value


                                                      }
                                            </span>

                                        </li>
                                    )
                                )
                            })}

                            {/* Changes made on 31st dec 24  at line 439*/}

                            {

(showUnpaidData != "Unpaid Policy Data")  ? (

                            <li className='acc-item action-item'>
                                <Button className="btn btn-link" onClick={() => handleAdvisorClick(item.agnt, item.agnt_nm)}>View</Button>
                            </li>) :(<></>)

}


                        </ul>
                    </AccordionDetails>
                </Accordion >

            ))
            }
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search' onClick={handlePrev} disabled={currentPage === 0}>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search' onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        <span className='icon icon-next'></span>
                    </IconButton>





                </div>
                <div className='right-wrap'>

                    <span>Page</span>
                    <span>{currentPage + 1}</span>
                    <span>of {totalPages}</span>
                </div>
            </div>
        </div >

    )
}

export default PersistencyGrid
