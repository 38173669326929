import React, { useState, useEffect } from "react";
import Loader from "../../../common/components/Loader";
import { jwtDecode } from "jwt-decode";
import { useFetchData } from "../../../hooks/common/useApiService";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import errorImg from "../../../common/assets/images/error.png";
import Button from "@mui/material/Button";

function AuthenticateByToken({ urlToken, departmentArray }) {
  const fetchData = useFetchData();
  const [loading, setLoading] = useState(true);
  const [loginLoader, setLoginLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const decoded = urlToken ? jwtDecode(urlToken) : "";
  const navigate = useNavigate();

  const fetchLoginLogs = (usertype, username) => {
    const logData = JSON.stringify({ token: "post_to_bigquery" });
    const loginHeaders = {
      "x-token-key": "BZz8VIdg39p0OxT",
      "Ocp-Apim-Subscription-Key": "8adcdc820a9c4e408ff4e26a43224427",
      "Content-Type": "application/json",
    };
    fetchData("post", "gcptoken/token-generation", logData, loginHeaders)
      .then((tokenResponse) => {
        const logToken = tokenResponse.data?.token;
        const logRecord = async () => {
          try {
            const ipResponse = await fetch("https://api.ipify.org?format=json");
            const ipData = await ipResponse.json();
            const ipAddress = ipData.ip;
            const dateTime = new Date().toUTCString();
            const userAgent = navigator.userAgent;
            const screenResolution = `${window.screen.width}x${window.screen.height}`;
            const language = navigator.language;
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const isTouchDevice =
              navigator.maxTouchPoints > 0 ? "true" : "false";
            const deviceOrientation = window.screen.orientation.type;
            const connectionType = navigator.connection
              ? navigator.connection.effectiveType
              : "unknown";
            const deviceMemory = navigator.deviceMemory || "unknown";
            const cpuCores = navigator.hardwareConcurrency || "unknown";

            const payload = {
              User_Type: usertype,
              Employee_Code: username,
              Agent_Code: localStorage.getItem("userId"),
              IP_Address: ipAddress,
              Date_Time: dateTime,
              User_Agent: userAgent,
              Screen_Resolution: screenResolution,
              Language: language + "| Prod",
              Time_Zone: timeZone,
              Is_Touch_Device: isTouchDevice,
              Device_Orientation: deviceOrientation,
              Connection_Type: connectionType,
              Device_Memory: deviceMemory,
              CPU_Cores: cpuCores,
            };
            const headers = {
              "Content-Type": "application/json",
              "Ocp-Apim-Subscription-Key": "8adcdc820a9c4e408ff4e26a43224427",
              Authorization: `Bearer ${logToken}`,
            };
            await fetch("https://fgliserviceprod.fglife.in/gcp/iris_logs", {
              method: "POST",
              headers: headers,
              body: JSON.stringify(payload),
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        logRecord();
      })
      .catch((error) => {
        console.error("Error fetching token:", error);
      });
  };
  useEffect(() => {
    try {
      if (decoded) {
        const filterArray = departmentArray?.some(
          (elem) => elem === decoded.department
        );
        const empCode =
          decoded.AgentType === "Agent"
            ? decoded.AGENT_CODE
            : decoded.EmployeeID;
        const userType =
          decoded.AgentType === "Agent" || decoded.AgentType === "Part-Time"
            ? "Agent"
            : decoded.AgentType === "SP" || decoded.AgentType === "IP"
            ? "Partner"
            : "Employee";
        if (filterArray) {
          let data = JSON.stringify({
            // "Type": values.usertype === "Agent" ? "2" : values.usertype === "Partner" ? "3" : "1",
            Type:
              decoded.AgentType === "Agent"
                ? "2"
                : decoded.AgentType === "SP" || decoded.AgentType === "IP"
                ? "3"
                : "1",
            // "Code": "1137965"
            Code: empCode,
          });
          let secondConfig = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://fgliserviceprod.fglife.in/AgentAPIExternal/AgentDetail/AgentDetailVW",
            headers: {
              "Content-Type": "application/json",
              "Ocp-Apim-Subscription-Key": "f519a06702f34732a30584456a8f8957",
              Authorization: "Basic aGx1c2VyOmgzckAxOTcwZy5D",
              IR_USERID: "webservice_user",
            },
            data: data,
          };
          axios
            .request(secondConfig)
            .then((secondResponse) => {
              if (!secondResponse.data.Message) {
                localStorage?.setItem("userId", secondResponse.data.AgentCode);
                localStorage?.setItem(
                  "username",
                  secondResponse.data.AgentName
                );
                localStorage?.setItem(
                  "userChannel",
                  secondResponse.data.Channel
                );
                localStorage?.setItem("usertype", userType);
                fetchLoginLogs(userType, empCode);
                // setLoginLoader(false);
                navigate("/dashboard");
              } else {
                const newData = JSON.stringify({ token: "prxy_mapping" });
                const newHeaders = {
                  "x-token-key": "BZz8VIdg39p0OxT",
                  "Ocp-Apim-Subscription-Key":
                    "8adcdc820a9c4e408ff4e26a43224427",
                  "Content-Type": "application/json",
                  // "agent_id": advisorcode
                };
                fetchData(
                  "post",
                  "gcptoken/token-generation",
                  newData,
                  newHeaders
                )
                  .then((response) => {
                    const tokenProxy = response.data?.token;
                    const payloadData = JSON.stringify({
                      employee_code: empCode,
                      // "employee_code": "1137965"
                    });
                    fetchData(
                      "post",
                      "gcp/iris_proxy",
                      payloadData,
                      null,
                      tokenProxy
                    )
                      .then((proxyResponse) => {
                        if (proxyResponse.data[0].statusCode === "200") {
                          // setLoading(false);
                          localStorage?.setItem(
                            "userId",
                            proxyResponse.data[0].response
                              .prxy_mapping_details[0].agentid
                          );
                          localStorage?.setItem(
                            "username",
                            proxyResponse.data[0].response
                              .prxy_mapping_details[0].agentname
                          );
                          localStorage?.setItem(
                            "userChannel",
                            proxyResponse.data[0].response
                              .prxy_mapping_details[0].channel
                          );
                          localStorage?.setItem("usertype", userType);
                          fetchLoginLogs(userType, empCode);
                          // setLoginLoader(false);
                          navigate("/dashboard");
                        } else {
                          setLoading(false);
                          setLoginLoader(false);
                          setOpen(true);
                          setErrorMessage("Unauthorised Access");
                        }
                      })
                      .catch((error) => {
                        console.error(
                          "Error fetching leakage-bar-api data:",
                          error
                        );
                        // setLoading(false);
                      });
                  })
                  .catch((error) => {
                    console.error("Error fetching tokenCard:", error);
                  });
              }
            })
            .catch((error) => {
              setLoading(false);
              setLoginLoader(false);
              setOpen(true);
              setErrorMessage(error);
            });
        } else {
          const newData = JSON.stringify({ token: "prxy_mapping" });
          const newHeaders = {
            "x-token-key": "BZz8VIdg39p0OxT",
            "Ocp-Apim-Subscription-Key": "8adcdc820a9c4e408ff4e26a43224427",
            "Content-Type": "application/json",
            // "agent_id": advisorcode
          };
          fetchData("post", "gcptoken/token-generation", newData, newHeaders)
            .then((response) => {
              const tokenProxy = response.data?.token;
              const payloadData = JSON.stringify({
                employee_code: empCode,
                // "employee_code": "1137965"
                // "employee_code": "1137965"
              });
              fetchData("post", "gcp/iris_proxy", payloadData, null, tokenProxy)
                .then((proxyResponse) => {
                  if (proxyResponse.data[0].statusCode === "200") {
                    // setLoading(false);
                    localStorage?.setItem(
                      "userId",
                      proxyResponse.data[0].response.prxy_mapping_details[0]
                        .agentid
                    );
                    localStorage?.setItem(
                      "username",
                      proxyResponse.data[0].response.prxy_mapping_details[0]
                        .agentname
                    );
                    localStorage?.setItem(
                      "userChannel",
                      proxyResponse.data[0].response.prxy_mapping_details[0]
                        .channel
                    );
                    localStorage?.setItem("usertype", userType);
                    fetchLoginLogs(userType, empCode);
                    // setLoginLoader(false);
                    navigate("/dashboard");
                  } else {
                    setLoading(false);
                    setLoginLoader(false);
                    setOpen(true);
                    setErrorMessage("Unauthorised Access");
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  setLoginLoader(false);
                  setOpen(true);
                  setErrorMessage(error);
                });
            })
            .catch((error) => {
              console.error("Error fetching tokenCard:", error);
            });
        }
      } else {
      }
    } catch (err) {
      console.log("Error decoding token: " + err.message);
    }
  }, [decoded, urlToken]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate("/");
    // window.location.reload();
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bs-modal"
      >
        <div className="modal-wrap">
          <span
            className="icon icon-close close-btn"
            onClick={handleClose}
          ></span>
          <div className="icon-wrap">
            <img src={errorImg} alt="checkImg" />
          </div>
          <h3 className="m-title">{errorMessage}</h3>
          <div className="action-wrap">
            <Button className="btn btn-default" onClick={handleClose}>
              Login Again
            </Button>
          </div>
        </div>
      </Modal>
      {loginLoader && (
        <div className="loader-wrap opaque">
          <div className="img-wrap">
            <div className="loader"></div>
            <span className="text">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
}

export default AuthenticateByToken;
