import React from "react";
import { DateRangePicker } from "rsuite";
import { useDateRange } from "../../../store/generalStore";
function DateRangeSelector() {
  const [dateValue, setDateValue] = useDateRange((state) => [
    state.dateValue,
    state.setDateValue,
  ]);

  const handleDate = (newValue) => {
    setDateValue(newValue);
  };
  const minDate = new Date(new Date().getFullYear() - 1, 1, 1);
  const maxDate = new Date();
  return (
    <div className="bs-date-range">
      <DateRangePicker
        onChange={handleDate}
        value={dateValue}
        disabledDate={(date) => date < minDate || date > maxDate}
        placeholder="Select Date Range"
        format="dd/MM/yyyy"
        ranges={[]}
        cleanable={false}
        placement="autoVerticalEnd"
        showOneCalendar={true}
      />
    </div>
  );
}

export default DateRangeSelector;
