import React, { useState, useEffect } from "react";
import useSize from "../../../../../hooks/common/useSize";
import loadIcon from "../../../../../common/assets/images/favicon.png";
import loadingImg from "../../../../../common/assets/images/three-dots.svg";
import PersistencyGrid from "../PersistencyGrid";
import { useFetchData } from "../../../../../hooks/common/useApiService";
import { dateRangeFormat } from "../../../../../hooks/common/useDateRangeFormat";
import Button from "@mui/material/Button";
import { MaterialReactTable } from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { formatValue, amountUnit } from "../../../../../utils/helper";

function PersistencyTable({
  cardName,
  filterRange,
  advisorcode,
  setAdvisorcode,
  dateValue,
  token,
  handleCompAdvisorClick,
  handlePoicyLevelDetails,
  SetshowUnpaidData,
  showUnpaidData,
}) {
  // function PersistencyTable({ cardName, filterRange, advisorcode, setAdvisorcode, dateValue, token, handleCompAdvisorClick,handlePoicyLevelDetails}) {
  const fetchData = useFetchData();
  const winDimensions = useSize();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [mergeArr, setMergeArr] = useState([]);

  let showUnpaidData_local = localStorage.getItem("showUnpaidData");

  // showUnpaidData = 'unpaid'
  useEffect(() => {
    var payloadData = JSON.stringify({
      agent_id: advisorcode,
    });

    var payloadDataPolicyDetails = JSON.stringify({
      agent_id: advisorcode,
      prsstncy_tg: "ALL",
      //  "prsstncy_tg": "13_month",
      Payment_Status: "UNPAID",
    });

    setLoading(true);
    // fetchData('post', 'gcp/Persistency_details_api_company',payloadData, null, token)?.then((response) => {
    //     var cardData = response?.data

    //     if (cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape") {
    //         cardData = response.data.APE
    //     } else {
    //         cardData = response.data.NOP
    //     }
    //     var detailsArr = cardData?.details;
    //     // var footerArr = cardData?.footer;
    //     // var mergeDetails = [...detailsArr, ...footerArr]
    //     var mergeDetails = [...detailsArr]
    //     setMergeArr(mergeDetails)
    //     setData(cardData)
    //     setFilteredData(mergeDetails);
    //     setLoading(false)
    // })

    if (showUnpaidData == "Unpaid Policy Data") {
      fetchData(
        "post",
        "gcp/Persistency_policywise_details_agency",
        payloadDataPolicyDetails,
        null,
        token
      )?.then((response) => {
        var cardData = response?.data;

        cardData = response.data.nop;
        var detailsArr = cardData?.details;

        // var footerArr = cardData?.footer;
        // var mergeDetails = [...detailsArr, ...footerArr]
        var mergeDetails = [...detailsArr];
        setMergeArr(mergeDetails);
        setData(cardData);

        setFilteredData(mergeDetails);
        setLoading(false);
      });
    } else {
      fetchData(
        "post",
        "gcp/Persistency_details_api_company",
        payloadData,
        null,
        token
      )?.then((response) => {
        var cardData = response?.data;

        if (
          cardName.toLowerCase() === "premium" ||
          cardName.toLowerCase() === "ape"
        ) {
          cardData = response.data.APE;
        } else {
          cardData = response.data.NOP;
        }
        var detailsArr = cardData?.details;
        // var footerArr = cardData?.footer;
        // var mergeDetails = [...detailsArr, ...footerArr]
        var mergeDetails = [...detailsArr];
        setMergeArr(mergeDetails);
        setData(cardData);
        setFilteredData(mergeDetails);
        setLoading(false);
      });
    }
  }, [cardName, advisorcode, dateValue, token]);

  const colData = (data?.columnname ?? [])
    .map((col) => {
      if (col && col.colname) {
        return {
          accessorKey: col.colname,
          header: col.displayName,
          muiTableHeadCellProps: {
            className: col.colname.includes("mtdgross")
              ? "mtd-class"
              : col.colname.includes("ytd")
              ? "ytd-class"
              : col.colname.includes("mtdnet")
              ? "mtd-net-class"
              : "",
          },
          size: col.dWidth,
          Cell: (props) => {
            // changed on line 395
            // if (cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "nop") {

            if (showUnpaidData === "Team Data") {
              if (cardName.toLowerCase() === "ape") {
                if (col.colname.includes("Percentage")) {
                  return (
                    <div
                      className={`mod-tag ${
                        props.cell.getValue() <= 85 ? "down" : ""
                      } ${
                        props.cell.getValue() > 85 && props.cell.getValue() < 95
                          ? "medium"
                          : ""
                      }`}
                    >
                      <span className="value">{props.cell.getValue()}</span>
                      <span className="icon icon-trending"></span>
                    </div>
                  );
                } else {
                  if (
                    col.colname === "agnt_nm" ||
                    col.colname === "details" ||
                    col.colname === "agnt" ||
                    col.colname === "plcy_nmbr"
                  ) {
                    return props.cell.getValue();
                  } else {
                    return amountUnit(props.cell.getValue(), filterRange);
                  }
                }
              } else {
                if (
                  col.colname === "agnt_nm" ||
                  col.colname === "Policy Number" ||
                  col.colname === "agnt" ||
                  col.colname === "prsstncy_tg" ||
                  col.colname === "Customer_Name"
                ) {
                  return props.cell.getValue();
                } else if (col.colname.includes("Percentage")) {
                  return (
                    <div
                      className={`mod-tag ${
                        props.cell.getValue() <= 85 ? "down" : ""
                      } ${
                        props.cell.getValue() > 85 && props.cell.getValue() < 95
                          ? "medium"
                          : ""
                      }`}
                    >
                      <span className="value">{props.cell.getValue()}</span>
                      <span className="icon icon-trending"></span>
                    </div>
                  );
                } else {
                  // chnage the on the fomarValue line 414 at 2nd Jan 25
                  // return amountUnit(props.cell.getValue(), filterRange)
                  return formatValue(props.cell.getValue());
                }
              }
            } else {
              if (
                col.colname === "policy_number" ||
                col.colname === "agnt_nm" ||
                col.colname === "new_policy_no" ||
                col.colname === "agnt" ||
                col.colname === "prsstncy_tg" ||
                col.colname === "Customer_Name" ||
                col.colname === "rcd"
              ) {
                return props.cell.getValue();
              } else if (col.colname.includes("Percentage")) {
                return (
                  <div
                    className={`mod-tag ${
                      props.cell.getValue() <= 85 ? "down" : ""
                    } ${
                      props.cell.getValue() > 85 && props.cell.getValue() < 95
                        ? "medium"
                        : ""
                    }`}
                  >
                    <span className="value">{props.cell.getValue()}</span>
                    <span className="icon icon-trending"></span>
                  </div>
                );
              } else {
                // chnage the on the fomarValue line 414 at 2nd Jan 25
                // return amountUnit(props.cell.getValue(), filterRange)
                return formatValue(props.cell.getValue());
              }
            }
            // if (col.colname === 'apeplan' || col.colname === 'apeactuals' || col.colname === 'wfypplan' || col.colname === 'wfypactuals' || col.colname === 'cpplan' || col.colname === 'cpactuals') {
            //     return amountUnit(props.cell.getValue(), filterRange);
            // }
            // else if (col.colname.includes("%")) {
            //     return <div className={`mod-tag ${props.cell.getValue() <= 85 ? "down" : ""} ${props.cell.getValue() > 85 && props.cell.getValue() < 95 ? "medium" : ""}`}>
            //         <span className='value'>{props.cell.getValue()}%</span>
            //         <span className='icon icon-trending'></span>
            //     </div>

            // } else {
            //     return props.cell.getValue();
            // }
          },
        };
      }
      return null;
    })
    .filter(Boolean);

  // if (showUnpaidData_local != 'unpaid')
  // {

  if (showUnpaidData != "Unpaid Policy Data") {
    colData.push({
      accessorKey: "action",
      header: "Action",
      size: 150,
      Cell: ({ cell, row }) => (
        // row.original.channel == "Group NB" || row.original.channel == "Total Retail" || row.original.channel == "Grand Total" || row.original.details == "Group NB" || row.original.details == "Total Retail" || row.original.details == "Grand Total" ? <></> :
        <Button
          className="btn btn-link"
          onClick={() =>
            handleCompAdvisorClick(row.original.agnt, row.original.agnt_nm)
          }
        >
          View
        </Button>
      ),
    });
  }
  // }

  const handleExport = (format) => {
    if (format === "excel") {
      const ws = XLSX.utils.json_to_sheet(data?.details);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      XLSX.writeFile(wb, "data.xlsx");
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchShow, setSearchShow] = React.useState(false);

  // const [grantTotal, setGrandTotal] = React.useState(['Grand Total'])

  const handleFilterSearch = (event, value) => {
    setSearchTerm(value);

    if (value === "") {
      setFilteredData(data?.details || []);

      // changes mdae on line 470 at 2nd Jan25 7:06

      // setFilteredData(mergeArr || []);
    } else {
      const lowercasedValue = value.toLowerCase();
      // const filtered = mergeArr.filter((item) =>
      //     Object.keys(item).some((key) =>
      //         item[key]?.toString().toLowerCase().includes(lowercasedValue)
      //     )

      // changes mdae on line 487 at 2nd Jan25 7:06

      const filtered = data?.details.filter((item) =>
        Object.keys(item).some((key) =>
          item[key]?.toString().toLowerCase().includes(lowercasedValue)
        )
      );

      // Add   setFilteredData(filtered) code on line 487 at 2nd Jan25 7:15

      setFilteredData(filtered);

      // const filterValues = filtered?.filter((elem) => elem?.channel === "Grand Total" || elem?.details === "Grand Total");
      // if (filterValues?.length > 0) {
      //     setGrandTotal(['Grand Total'])
      // } else {
      //     setGrandTotal([])
      // }
      // setFilteredData(filtered);
    }
  };

  const handleSearchClear = () => {
    setSearchTerm("");
    // changes mdae on line 508 at 2nd Jan25 7:06
    setFilteredData(data?.details || []);
    setSearchShow(false);
    // setGrandTotal(['Grand Total'])
  };
  const handleSearch = () => {
    setSearchShow(true);
  };

  return (
    <>
      <div
        className={`bs-panel typ-table typ-auto ${data?.headingcolour} ${data?.bgcolor}`}
      >
        <div className="panel-head">
          <div className="left-wrap">
            {/* <h2 className='p-title'>{data?.name}</h2> */}

            <div className="bs-select typ-sm typ-toggle cm-hidden-sm">
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value=""
                  renderValue={(selected) => showUnpaidData}
                  onChange={(e) => handlePoicyLevelDetails(e.target.value)}
                  IconComponent={(props) => (
                    <span
                      {...props}
                      className={`icon-chevron-down ${props.className}`}
                    ></span>
                  )}
                  MenuProps={{
                    classes: {
                      paper: "bs-menu-dropdown",
                    },
                  }}
                  displayEmpty
                >
                  <MenuItem value="Unpaid Policy Data">
                    Unpaid Policy Data
                  </MenuItem>
                  <MenuItem value="Team Data">Team Data</MenuItem>
                  {/* <MenuItem value="pdf">Export to PDF</MenuItem> */}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="right-wrap">
            <div className={`bs-search  ${searchShow ? "active" : ""}`}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                open={false}
                value={searchTerm}
                options={filteredData
                  ?.flatMap(Object.values)
                  .map((value) => value.toString())}
                onInputChange={handleFilterSearch}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,

                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={handleSearchClear}
                        >
                          <span
                            className={`icon icon-${
                              searchTerm === "" ? "search" : "close"
                            }`}
                          ></span>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search"
                  />
                )}
                classes={{ paper: "bs-menu-dropdown" }}
              />
            </div>
            <div className="bs-select typ-sm typ-toggle cm-hidden-sm">
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value=""
                  renderValue={(selected) => "Export"}
                  onChange={(e) => handleExport(e.target.value)}
                  IconComponent={(props) => (
                    <span
                      {...props}
                      className={`icon-chevron-down ${props.className}`}
                    ></span>
                  )}
                  MenuProps={{
                    classes: {
                      paper: "bs-menu-dropdown",
                    },
                  }}
                  displayEmpty
                >
                  <MenuItem value="excel">Export to Excel</MenuItem>
                  {/* <MenuItem value="pdf">Export to PDF</MenuItem> */}
                </Select>
              </FormControl>
            </div>

            <div className="cm-hidden-md">
              <IconButton
                disableRipple
                className="btn btn-search"
                onClick={handleSearch}
              >
                <span className="icon icon-search"></span>
              </IconButton>
            </div>
          </div>
        </div>
        <div className="panel-content">
          {winDimensions[0] <= 768 ? (
            loading ? (
              <div className="cm-loader">
                <div className="load-img">
                  <img src={loadIcon} alt="loader" />
                  <img src={loadingImg} alt="loading" className="loading-gif" />
                </div>
              </div>
            ) : (
              <PersistencyGrid
                data={data}
                filterRange={filterRange}
                cardName={cardName}
                advisorcode={advisorcode}
                setAdvisorcode={setAdvisorcode}
                filteredData={filteredData}
                handleCompAdvisorClick={handleCompAdvisorClick}
                handlePoicyLevelDetails={handlePoicyLevelDetails}
                SetshowUnpaidData={SetshowUnpaidData}
                showUnpaidData={showUnpaidData}
              />
            )
          ) : loading ? (
            <div className="cm-loader">
              <div className="load-img">
                <img src={loadIcon} alt="loader" />
                <img src={loadingImg} alt="loading" className="loading-gif" />
              </div>
            </div>
          ) : (
            <div className="bs-pinned-table">
              <MaterialReactTable
                data={filteredData}
                columns={colData}
                getRowId={(row) => row?.details || row?.channel}
                initialState={{
                  columnVisibility: { Pin: false },
                  rowPinning: {
                    bottom: [],
                    // top: grantTotal,
                    // top: [],
                  },
                }}
                enableColumnActions={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableFiltering={false}
                enableSorting={false}
                // enableRowPinning={grantTotal?.length > 0 && filteredData?.length > 0 ? true : false}
                enablePagination={false}
                enableStickyHeader={true}
                enableRowActions={false}
                enableRowSelection={false}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PersistencyTable;
